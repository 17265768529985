.DialogContent {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}
.DialogOverlay {
    overflow-y: auto;
    background-color: rgba(0 0 0 / 0.5);
    position: fixed;
    inset: 0;
    animation: dialogOverlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

@keyframes dialogOverlayShow {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes contentShow {
    from {
        opacity: 0;
        transform: translate(-50%, -48%) scale(0.96);
    }
    to {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}

.TabsTrigger {
    color: theme('colors.zinc.400');
}

.TabsTrigger:hover {
    color: theme('colors.lime.500');
}

.TabsTrigger:active {
    color: theme('colors.lime.600');
}

.TabsTrigger[data-state='active'] {
    color: theme('colors.zinc.200');
    box-shadow: inset 0 -1px 0 0 theme('colors.lime.500'), 0 1px 0 0 theme('colors.lime.500');
}

.TabsTrigger[data-state='active']:hover {
    color: theme('colors.lime.500');
}

.TabsTrigger[data-state='active']:active {
    color: theme('colors.lime.600');
}

.TransitionWidth {
    -webkit-transition: width 660ms ease-in-out;
    -moz-transition: width 660ms ease-in-out;
    -o-transition: width 660ms ease-in-out;
    transition: width 660ms ease-in-out;
}
.AlertDialogOverlay {
    background-color: theme('colors.zinc.950');
    position: fixed;
    inset: 0;
    animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    opacity: 0.5;
}

@keyframes overlayShow {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.5;
    }
}
.ColorSwatch {
    border: none;
    outline: none;
    -webkit-appearance: none;
    border-radius: 0.375rem;
    outline-width: 2px;
    outline-style: solid;
    outline-color: theme('colors.zinc.800');
    cursor: pointer;
    transition-property: outline-color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.ColorSwatch::-webkit-color-swatch-wrapper {
    padding: 0;
}

.ColorSwatch::-webkit-color-swatch {
    border: none;
    border-radius: 0.375rem;
}

.ColorSwatch:focus {
    outline-width: 2px;
    outline-style: solid;
    outline-color: theme('colors.zinc.100');
}
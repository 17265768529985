.LabelDropdownShow {
  opacity: 0;
  pointer-events: none;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.LabelDropdownContainer:hover .LabelDropdownShow {
  opacity: 1;
  pointer-events: auto;
}

.LabelDropdownContainer:focus .LabelDropdownShow {
  opacity: 1;
  pointer-events: auto;
}

.LabelDropdownContainer:focus .LabelDropdownColor {
  color: theme('colors.lime.500');
}

.InsertButton {
  opacity: 0;
  pointer-events: none;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.LabelDropdownContainerDivider:hover .InsertButton {
  opacity: 1;
}

.LabelDropdownContainerDivider:focus .InsertButton {
  opacity: 1;
}

.TextLimeBlink {
  animation: text-lime-blink 1.0s;
  animation-iteration-count: 1;
}

@keyframes text-lime-blink {
  0% {
    color: theme('colors.zinc.400');
  }

  50% {
    color: theme('colors.lime.300');
  }

  100% {
    color: theme('colors.zinc.400');
  }
}
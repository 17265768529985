/* The fast way */
.AnimateShadowOpacity {
    position: relative;
}

/* Pre-render the bigger shadow, but hide it */
.AnimateShadowOpacity::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    box-shadow: 0 0px 15px 5px var(--tw-shadow-color);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

/* Transition to showing the bigger shadow on hover */
.AnimateShadowOpacity:hover::after {
    opacity: 1;
}
.ContainerWithHorizontalDividers>*:not(:last-child) {
    border-bottom: 1px solid theme('colors.zinc.800');
    /* Add a 1px horizontal line below each child */
    padding-bottom: 1.8em;
    margin-bottom: 1.8em;
}


.ContainerWithVerticalDividers>*:not(:last-child) {
    border-right: 1px solid theme('colors.zinc.900');
    /* Adjust the color as needed */
    padding-right: 1.5vw;
    /* Add some space to the right of the border, if desired */
    margin-right: 1.5vw;
    /* Add margin to prevent the next item from sticking to the border */
}
.dropdownItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0.25rem;
    padding: 0.5rem;
    user-select: none;
}

.dropdownItem:focus-visible {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.dropdownSelectable {
    cursor: pointer;
    border-radius: 0.375rem;
    outline: 2px solid transparent;
    outline-offset: 2px;
}

@keyframes overlayShow {
    from {
        opacity: 0;
    }

    to {
        opacity: 0.5;
    }
}
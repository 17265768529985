.Pulse {
    display: block;
    box-shadow: 0 0 0 var(--tw-shadow-color);
    animation: pulse 2s infinite;
}
.Pulse:hover {
    animation: none;
}

@-webkit-keyframes pulse {
    0% {
    -webkit-box-shadow: 0 0 0 0 var(--tw-shadow-color);
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
}
@keyframes pulse {
    0% {
    -moz-box-shadow: 0 0 0 0 var(--tw-shadow-color);
    box-shadow: 0 0 0 0 var(--tw-shadow-color);
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
        box-shadow: 0 0 0 10px rgba(204,169,44, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
        box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
}
.DropdownMenuContent,
.DropdownMenuSubContent {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
}

.DropdownMenuContent[data-side='top'],
.DropdownMenuSubContent[data-side='top'] {
    animation-name: slideDownAndFade;
}

.DropdownMenuContent[data-side='right'],
.DropdownMenuSubContent[data-side='right'] {
    animation-name: slideLeftAndFade;
}

.DropdownMenuContent[data-side='bottom'],
.DropdownMenuSubContent[data-side='bottom'] {
    animation-name: slideUpAndFade;
}

.DropdownMenuContent[data-side='left'],
.DropdownMenuSubContent[data-side='left'] {
    animation-name: slideRightAndFade;
}

.DropdownMenuTrigger .DropdownIcon {
    color: theme('colors.zinc.700')
}

.DropdownMenuTrigger:hover .DropdownIcon {
    color: theme('colors.lime.500')
}

.RightSlot {
    margin-left: auto;
    padding-left: 20px;
    color: theme('colors.zinc.500');
}

[data-highlighted]>.RightSlot {
    color: theme('colors.zinc.300');
}

[data-disabled] .RightSlot {
    color: theme('colors.zinc.500');
}

@keyframes slideUpAndFade {
    from {
        opacity: 0;
        transform: translateY(2px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideRightAndFade {
    from {
        opacity: 0;
        transform: translateX(-2px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideDownAndFade {
    from {
        opacity: 0;
        transform: translateY(-2px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideLeftAndFade {
    from {
        opacity: 0;
        transform: translateX(2px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}
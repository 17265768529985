.ScrollAreaRoot {
    overflow: hidden;
    --scrollbar-size: 10px;
}

.ScrollAreaViewport {
    width: 100%;
    height: 100%;
    border-radius: inherit;
}

.ScrollAreaScrollbar {
    display: flex;
    /* ensures no selection */
    user-select: none;
    /* disable browser handling of all panning and zooming gestures on touch devices */
    touch-action: none;
    padding: 2px;
    background: transparent;
    transition: background 160ms ease-out;
}

.ScrollAreaScrollbar[data-orientation='vertical'] {
    width: var(--scrollbar-size);
}

.ScrollAreaScrollbar[data-orientation='horizontal'] {
    flex-direction: column;
    height: var(--scrollbar-size);
}

.ScrollAreaThumb {
    flex: 1;
    background: theme('colors.zinc.800');
    border-radius: var(--scrollbar-size);
    position: relative;
    z-index: 1;
}

.ScrollAreaThumb:hover {
    background: theme('colors.zinc.700');
}

/* increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html */
.ScrollAreaThumb::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    min-width: 44px;
    min-height: 44px;
}

.ScrollAreaCorner {
    background: transparent;
}
/* reset */
button {
    all: unset;
}

.SwitchRoot {
    width: 42px;
    height: 25px;
    background-color: theme('colors.zinc.800');
    border-radius: 9999px;
    position: relative;
    box-shadow: 0 0px 5px theme('colors.zinc.900');
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.SwitchRoot:focus {
    box-shadow: 0 0 0 1px theme('colors.zinc.700');
}

.SwitchRoot[data-state='checked'] {
    background-color: theme('colors.lime.500');
}

.SwitchThumb {
    display: block;
    width: 21px;
    height: 21px;
    background-color: theme('colors.zinc.100');
    border-radius: 9999px;
    box-shadow: 0 2px 2px var(--blackA7);
    transition: transform 100ms;
    transform: translateX(2px);
    will-change: transform;
}

.SwitchThumb[data-state='checked'] {
    transform: translateX(19px);
}

.Label {
    color: white;
    font-size: 15px;
    line-height: 1;
    user-select: none;
}
.ToggleGroupItem[data-state='on'] {
    background-color: theme('colors.lime.500');
    color: theme('colors.zinc.800');
}

.ToggleGroupItem[data-state='on']:hover {
    background-color: theme('colors.lime.400');
}

.ToggleGroupItem[data-state='off'] {
    background-color: theme('colors.zinc.800');
    color: theme('colors.zinc.300');
}

.ToggleGroupItem[data-state='off']:hover {
    background-color: theme('colors.zinc.700');
}
@tailwind base;
@tailwind components;
@tailwind utilities;


html {
  overscroll-behavior-x: none;
  overscroll-behavior-y: none;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-x: none;
  overscroll-behavior-y: none;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Utils */

.drag-none {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

/* Canvas */

#brushcursor {
  pointer-events: none;
  background-color: rgba(30, 34, 26, 0.5);
  border: 2px solid rgba(132, 204, 22, 0.2);
  height: 50px;
  width: 50px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  position: absolute;
  z-index: 1;
}

/* loader spinner */



.loader,
.loader:after {
  border-radius: 50%;
  width: 2em;
  height: 2em;
}

.loader {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.25em solid rgba(255, 255, 255, 0.2);
  border-right: 0.25em solid rgba(255, 255, 255, 0.2);
  border-bottom: 0.25em solid rgba(255, 255, 255, 0.2);
  border-left: 0.25em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* custom scrollbar */
:root {
  scrollbar-color: #27272a transparent !important;
  scrollbar-width: auto;
}

@-moz-document url-prefix() {

  scrollbar-container {
    padding-left: 12px;
    padding-right: 16px;
  }

}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #27272a;
  border-radius: 8px;
  border: 3px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #3f3f46;
}

.error-text-node {
  box-shadow: 0 2px 0 theme('colors.red.500');
  background-color: theme('colors.red.800');
  border-radius: 0.125rem;
}

/* Shadows */

.shadow-generation-frame {
  box-shadow: 0px 0px 10px 10px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
}

.shadow-lg-center {
  box-shadow: 0 0 15px -3px var(--tw-shadow-color);
}

/* Generation frame */

.bg-radial-lime-500 {
  background: radial-gradient(rgba(132, 204, 22, 0.05), rgba(132, 204, 22, 0.1));
}

/* Z index */

.z-100 {
  z-index: 100;
}

/* Number Input */

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.AlertDialogOverlay {
    background-color: theme('colors.zinc.950');
    position: fixed;
    inset: 0;
    animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    opacity: 0.5;
}


@keyframes overlayShow {
    from {
        opacity: 0;
    }

    to {
        opacity: 0.5;
    }
}
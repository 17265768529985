.dropdownItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0.25rem;
    padding: 0.5rem;
    user-select: none;
}

.dropdownItem:focus-visible {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.dropdownSelectable {
    cursor: pointer;
    border-radius: 0.375rem;
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.AlertDialogOverlay {
    background-color: theme('colors.zinc.950');
    position: fixed;
    inset: 0;
    animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    opacity: 0.5;
}

.DashboardGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 16px 16px;
}
.DashboardWorkflowGrid {
    display: grid;
    grid-gap: 16px 16px;
}
@media (max-width: 1280px) {
    .DashboardWorkflowGrid {
        grid-template-columns: repeat(3, minmax(230px, 1fr));
    }
}
@media (min-width: 1281px) and (max-width: 1920px) {
    .DashboardWorkflowGrid {
        grid-template-columns: repeat(4, minmax(230px, 1fr));
    }
}
@media (min-width: 1921px) {
    .DashboardWorkflowGrid {
        grid-template-columns: repeat(5, minmax(230px, 1fr));
    }
}

.DashboardButtonsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    grid-gap: 16px 16px;
}

@keyframes overlayShow {
    from {
        opacity: 0;
    }

    to {
        opacity: 0.5;
    }
}
.ContextMenuContent,
.ContextMenuSubContent {
  min-width: 220px;
  background-color: theme('colors.zinc.800');
  border-radius: 6px;
  overflow: hidden;
  padding: 5px;
  z-index: 102;
  border-width: 1px;
  border-style: solid;
  border-color: theme('colors.zinc.700');
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  font-size: 0.875rem;
  /* 14px */
  line-height: 1.25rem;
  /* 20px */
}

.ContextMenuItem,
.ContextMenuCheckboxItem,
.ContextMenuRadioItem,
.ContextMenuSubTrigger {
  font-size: 13px;
  line-height: 1;
  color: theme('colors.zinc.300');
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 25px;
  padding: 12px 8px;
  position: relative;
  user-select: none;
  outline: none;
}

.ContextMenuSubTrigger[data-state='open'] {
  background-color: theme('colors.zinc.800');
  color: theme('colors.zinc.300');
}

.ContextMenuItem[data-disabled],
.ContextMenuCheckboxItem[data-disabled],
.ContextMenuRadioItem[data-disabled],
.ContextMenuSubTrigger[data-disabled] {
  color: theme('colors.zinc.500');
  pointer-events: 'none';
}

.ContextMenuItem[data-highlighted],
.ContextMenuCheckboxItem[data-highlighted],
.ContextMenuRadioItem[data-highlighted],
.ContextMenuSubTrigger[data-highlighted] {
  background-color: theme('colors.zinc.700');
  color: theme('colors.zinc.100');
}


.ContextMenuLabel {
  padding-left: 25px;
  font-size: 12px;
  line-height: 25px;
  color: theme('colors.zinc.800');
}

.ContextMenuSeparator {
  height: 1px;
  background-color: theme('colors.zinc.800');
  margin: 5px;
}

.ContextMenuItemIndicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.RightSlot {
  margin-left: auto;
  padding-left: 20px;
  color: theme('colors.zinc.500');
}

[data-highlighted]>.RightSlot {
  color: theme('colors.zinc.300');
}

[data-disabled] .RightSlot {
  color: theme('colors.zinc.500');
}
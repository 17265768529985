@keyframes opacityPulse {
    0% {
        opacity: 0.5;
    }

    10% {
        opacity: 0.5;
    }

    50% {
        opacity: 1;
    }

    90% {
        opacity: 0.5;
    }

    100% {
        opacity: 0.5;
    }
}

.OpacityPulse {
    animation-name: opacityPulse;
    animation-duration: 3s;
    /* Adjust duration as needed */
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    transition-property: opacity;
}

.ProgressText {
    opacity: var(--progress-percentage);
}
.TabsTrigger {
    border-color: transparent;
    color: theme("colors.zinc.400");
}

.TabsTrigger[data-state='active'] {
    border-color: theme("colors.lime.600");
    color: theme("colors.zinc.300");
}

.TabsTrigger[data-state='active']:hover {
    border-color: theme("colors.lime.500");
}